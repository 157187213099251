import React from "react";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
  Table,
  Card,
  Pagination, PaginationItem, PaginationLink 
} from "reactstrap";


function SectionCategorias() {
  React.useEffect(() => {
   
   
  });
  return (
    <>
      <div className="section section-buttons">
        <Container>
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Categorias de Produtos</h1>
          </div>

       <div>
        <hr />

      </div>
          <Row>
            <Col sm style={{textAlign:"right"}}>

            <InputGroup  style={{width:"40%", textAlign:"right", marginTop:30}} >
        <InputGroupAddon addonType="prepend">
          <InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
        </InputGroupAddon>
        <Input type="text"placeholder="Buscar categorias..." />
        <Button
               
                color="secondary"
                href="#"
                >
           ok
              </Button>
      </InputGroup>

            <Button
                className="btn-round"
                color="success"
                href="#"
                >
            nova 
              </Button>
              &nbsp; &nbsp;
          
            <Button
                className="btn-round"
                color="info"
                href="#"
                >
            editar 
              </Button>
              &nbsp; &nbsp;
            <Button
                className="btn-round"
                color="danger"
                href="#"
                >
            excluir
              </Button>
            </Col>
          </Row>

          <br />

          <Table striped>
          <thead>
        <tr>
          <th>
          <FormGroup check>
        <Label style={{marginBottom:10}} check>
          <Input type="checkbox" />{' '}
            <span className="form-check-sign">
            <span className="check"></span>
               </span>
        </Label>
      </FormGroup>
          </th>
          <th>#</th>
          <th>Data</th>
          <th>Nome da Categoria</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <th>
          <FormGroup check>
        <Label style={{paddingBottom:15}} check>
          <Input type="checkbox" />{' '}
            <span className="form-check-sign">
            <span className="check"></span>
               </span>
        </Label>
      </FormGroup>
          </th>
          <td>1</td>
          <td>11/04/2023</td>
          <td>Categoria 1</td>
          <td style={{fontWeight:600, color:"#6BD098"}}>
          Publicado
          </td>
        </tr>
        <tr>
        <th>
          <FormGroup check>
        <Label style={{paddingBottom:15}} check>
          <Input type="checkbox" />{' '}
            <span className="form-check-sign">
            <span className="check"></span>
               </span>
        </Label>
      </FormGroup>
          </th>
          <td>2</td>
          <td>11/04/2023</td>
          <td>Categoria 2</td>
          <td style={{fontWeight:600, color:"#6BD098"}}>
          Publicado
          </td>
        </tr>
        <tr>
        <th>
          <FormGroup check>
        <Label style={{paddingBottom:15}} check>
          <Input type="checkbox" />{' '}
            <span className="form-check-sign">
            <span className="check"></span>
               </span>
        </Label>
      </FormGroup>
          </th>
          <td>3</td>
          <td>11/04/2023</td>
          <td>Categoria 3</td>
          <td style={{fontWeight:600, color:"#6BD098"}}>
          Publicado
          </td>
        </tr>
        <tr>
        <th>
          <FormGroup check>
        <Label style={{paddingBottom:15}} check>
          <Input type="checkbox" />{' '}
            <span className="form-check-sign">
            <span className="check"></span>
               </span>
        </Label>
      </FormGroup>
          </th>
          <td>4</td>
          <td>11/04/2023</td>
          <td>Categoria 4</td>
          <td style={{fontWeight:400,}}>
          Rascunho
          </td>
        </tr>
       
    
       
       
        
        
        
      </tbody>
    </Table>
<hr />
    <nav aria-label="Page navigation example">
        <Pagination
          className="pagination justify-content-right"
          listclassName="justify-content-center"
        >
          <PaginationItem className="disabled">
            <PaginationLink
              href="#"
              onClick={e => e.preventDefault()}
              tabindex="-1"
            >
             Anterior
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" onClick={e => e.preventDefault()}>
              1
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" onClick={e => e.preventDefault()}>
              2
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" onClick={e => e.preventDefault()}>
              3
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" onClick={e => e.preventDefault()}>
              Próximo
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>

        </Container>
      </div>
    </>
  );
}

export default SectionCategorias;
