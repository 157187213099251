import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="#">
                  SEB STORE
                </a>
              </li>
              <li>
                <a
                  href="#"
                >
                  GRUPO SEB
                </a>
              </li>
              <li>
                <a
                  href="#"
                >
                  TERMOS DE USO
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, FEITO COM {" "}
              <i className="fa fa-heart heart" /> PELO GRUPO SEB.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
