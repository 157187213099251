
import React, { useEffect, useState } from "react";
import axios from 'axios';

import api from "../../services/api.js";


// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
  Card,
} from "reactstrap";

function Main() {

  const [produtos, setProdutos] = useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
        const result = await axios(
            'https://www.rocketleoo.com.br/sebstore/api/produto/'
        );
        setProdutos(result.data);
    }
    fetchData()
    
}, []);
  return (
    <>
      <div className="section section-buttons">
        <Container style={{marginTop:-300,}}>
        <Row xs="4">
        {produtos.map(produto => (            
             <Col sm>
             <Card>
               <div style={{padding:15, fontWeight:400,}}>
               <img src={require(`../../../public/uploads/${produto.img}`).default} style={{height:150, width:"100%"}} />

               
               </div>
               <div style={{paddingLeft:15, paddingRight:15, fontWeight:600,}}>         
               {produto.nome}
               </div>
               <div style={{paddingLeft:15, paddingRight:15, fontWeight:400, whiteSpace:"nowrap", width:"18em", overflow:"hidden", textOverflow:"ellipsis"}}>         
               {produto.desc}
               </div>
               <div style={{paddingLeft:15, marginTop:15, marginBottom:15, paddingRight:15, fontWeight:400,}}>  
               <Row>
                 <Col>
                 <span style={{fontWeight:600, fontSize:22, color:"#F5593D"}}>{produto.boots} <i className="nc-icon nc-spaceship"></i></span>
                 </Col>
                 <Col>
                 <Button
               className="btn-round"
               color="danger"
               href={`/produto/${produto._id}`}
               style={{width:"100%"}} 
               >
           Trocar
             </Button>
                 </Col>
               </Row>
               </div>
             </Card>
             </Col>
             ))} 
        </Row>
      
        
          
        </Container>
      </div>
    </>
  );
}

export default Main;
