
import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import api from "../services/api.js";


// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "reactstrap";


class TrocasAdmin extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);

  
    this.state = {  
      trocas:[],
      users:[],
      produtos:[],
      unidade:[],
      filter:[],
      modal: false,
      modal2: false,
      modal3: false,
      user:  props.user,
      produto:  props.produto,
      boots:  props.boots,
      status:  props.status,
      selectedPost: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  toggle3 = i => {
    this.setState({
      modal3: !this.state.modal3,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  async componentDidMount() {

       axios.get(`https://www.rocketleoo.com.br/sebstore/api/troca`)
    .then((response) => {
      const trocas = response.data;
      const filter = response.data;
      this.setState({
        trocas, filter
      })
    })
            
  }

  handleChangeStatus = (event) => {
    event.persist();
    this.setState({ status: event.target.value });
  }

    
   handleUpdate = event => {
      event.preventDefault();  

      if (this.state.selectedPost !== null) {
        const troca = this.state.trocas[this.state.selectedPost];
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://www.rocketleoo.com.br/sebstore/api/troca/${troca._id}`, {
        user: troca.user,
        produto: troca.produto,
        boots: troca.boots,
        status: this.state.status, 
        }, config)
          .then(res => {
            alert('Status alterado com sucesso!');
            this.props.history.push("/trocas-admin");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }

      }


      renderTrocas() {
      return this.state.filter.map((troca, i) => {

         
          return (
            <tr key={troca._id}>    
            <td> {moment(troca.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:400}}> {troca.produto.nome}</td>   
            <td style={{fontWeight:400}}> {troca.user.nome}</td>  
            <td style={{fontWeight:400}}> {troca.user.email}</td>  
            <td style={{fontWeight:400}}> {troca.boots}</td>  
            <td style={{fontWeight:400}}> 
            
            {(() => {
        switch (troca.status) {
          case '0':
            return <Alert color="danger" size="sm" style={{padding:6, textAlign:"center"}}> Cancelado </Alert>
          case '1':
            return <Alert color="warning" size="sm" style={{padding:6, textAlign:"center"}}> Aguardando confirmação </Alert>
          case '2':
            return <Alert color="primary" size="sm" style={{padding:6, textAlign:"center"}}> Confirmado</Alert>
          case '3':
            return <Alert color="primary" size="sm" style={{padding:6, textAlign:"center"}}> Enviado</Alert>
          case '4':
            return <Alert color="success" size="sm" style={{padding:6, textAlign:"center"}}> Entregue</Alert>
          default:
            return null
        }
      })()}
            
            
            </td>            
             
                  <td><Button
                    size="sm"
                    color="info"
                    onClick={() => this.toggle2(i)}
                    
                    >
                <FiEdit2 />
                  </Button></td> 
            </tr>
          );
      });
  }
  
  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const troca = this.state.trocas[this.state.selectedPost];

     

           axios.get(`https://www.rocketleoo.com.br/sebstore/api/user/${troca.user._id}`)
           .then((response) => {
            const users = response.data;
            const unidade = response.data.id_unidade;          
            this.setState({
              users, unidade
            })
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });


          console.log(this.state.unidade.nome)


      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Alterar Status do Pedido</span></ModalHeader>
          <ModalBody>
                

            <label htmlFor="user"><span style={{fontWeight:600}}>Pedido</span></label><br />
       
      {troca._id} 
        <br />  <br />
         
<label htmlFor="user"><span style={{fontWeight:600}}>Colaborador</span></label><br />
       
      {troca.user.nome} 
        <br />  <br />

        <label htmlFor="user"><span style={{fontWeight:600}}>E-mail</span></label><br />
       
      {troca.user.email} 
        <br />  
        <hr />

        <label htmlFor="user"><span style={{fontWeight:600}}>Produto</span></label><br />

        {troca.produto.nome}  <br />  <br />

        <label htmlFor="user"><span style={{fontWeight:600}}>Descrição</span></label><br />

        {troca.produto.desc}  <br />  <br />

        <label htmlFor="user"><span style={{fontWeight:600}}>Boot's</span></label><br />

        {troca.boots}  <br /> 

        <hr />

        <label htmlFor="user"><span style={{fontWeight:600}}>Unidade</span></label><br />

        {this.state.unidade.nome}  <br />  <br />

        <label htmlFor="user"><span style={{fontWeight:600}}>Endereço de entrega</span></label><br />

        {this.state.unidade.endereco}  <br />  <br />

        <label htmlFor="user"><span style={{fontWeight:600}}>Complemento</span></label><br />

{this.state.unidade.complemento}  <br />  <br />

<label htmlFor="user"><span style={{fontWeight:600}}>Bairro</span></label><br />

{this.state.unidade.bairro}  <br />  <br />

<label htmlFor="user"><span style={{fontWeight:600}}>Cidade</span></label><br />

{this.state.unidade.cidade}  <br />  <br />

<label htmlFor="user"><span style={{fontWeight:600}}>Estado</span></label><br />

{this.state.unidade.estado}  <br />  <br />

<label htmlFor="user"><span style={{fontWeight:600}}>CEP</span></label><br />

{this.state.unidade.cep}  <br />  <br />

        <label htmlFor="tipoboots"><span style={{fontWeight:600}}>Status</span></label>
        <Input type="select" name="status" id="status" onChange={this.handleChangeStatus}>                  
               
   
        {(() => {
        switch (troca.status) {
          case '0':
            return <option value="0" selected  style={{color:"#6BD098", fontWeight:600}}>Cancelado</option> 
          case '1':
            return <option value="1" selected style={{color:"#6BD098", fontWeight:600}}>Aguardando confirmação</option>
          case '2':
            return <option value="2" selected style={{color:"#6BD098", fontWeight:600}}>Confirmado</option> 
          case '3':
            return <option value="3" selected style={{color:"#6BD098", fontWeight:600}}>Enviado</option> 
          case '4':
            return <option value="4" selected style={{color:"#6BD098", fontWeight:600}}>Entregue</option> 
          default:
            return <option value="" selected style={{color:"#6BD098", fontWeight:600}}>Selecione...</option> 
        }
      })()}

<option value="0">Cancelado</option> 
<option value="1">Aguardando confirmação</option> 
<option value="2">Confirmado</option> 
<option value="3">Enviado</option> 
<option value="4">Entregue</option> 

  
  </Input>
        <br />

      
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" href="/trocas-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }




  render() {

    const { modal2, trocas } = this.state;


    const requestSearch = (searchedVal) => {
      const filteredRows = trocas.filter((row) => {
          return row.user.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) || row.user.email.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      });
      if (searchedVal.length < 1) {
          this.setState({filter: trocas});
      }
      else {
        this.setState({filter: filteredRows});
      }
    };

  return (
    <>
      <GerenciadorNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Pedidos de Trocas</h1>
          </div>

          <br />

          <form>
<Row style={{marginTop:0}}>
<Col>    


<InputGroup>
<InputGroupAddon addonType="prepend">
<InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
</InputGroupAddon>
<Input type="search" placeholder="Digite o nome ou e-mail do colaborador..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
</InputGroup>



</Col>                   
</Row>
</form>

<br />
          
      

        <Modal isOpen={this.state.modal2} className="modal-lg">
        {this.renderModal(this.state.trocas[this.state.selectedPost])}
        </Modal>
        

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th>Produto</th>  
          <th>Colaborador</th>  
          <th>E-mail</th> 
          <th>Boot's</th> 
          <th>Status</th>
          <th width="5%">&nbsp;</th>    
        </tr>
      </thead>


      <tbody>
   {this.renderTrocas()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     
        <DemoFooter />
        </div>
      </div>
    </>
  );
}}

export default withRouter(TrocasAdmin);
