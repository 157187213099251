
import React from "react";

// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections
import SectionBoots from "views/index-sections/SectionBoots.js";


function Boots() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="main">
        <SectionBoots />
        <DemoFooter />
      </div>
    </>
  );
}

export default Boots;
