import React, { useEffect, useState } from "react";
import axios from 'axios';
import classnames from "classnames";

import { Link } from 'react-router-dom';

import { SlMagnifier } from "react-icons/sl";
import { SlUser } from "react-icons/sl";
import { SlQuestion } from "react-icons/sl";
import { SlPower } from "react-icons/sl";
import api from "../../services/api.js";
import TotalBoots from "../Headers/TotalBoots.js";


// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal
} from "reactstrap";

import logo from "../../assets/img/logo.png";

function IndexNavbar() {
  const [largeModal, setLargeModal] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState("#FFF");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [categorias, setCategorias] = useState([]);
  

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const [user, setUser] = React.useState(() => {
    // getting stored value
    const saved = localStorage.getItem("user");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  React.useEffect(() => {

  
      const fetchData = async () => {
        const result = await axios(
            'https://www.rocketleoo.com.br/sebstore/api/categoria/'
        );
        setCategorias(result.data);
    }
    fetchData();
  

  
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 0 ||
        document.body.scrollTop > 0
      ) {
        setNavbarColor("#FFF");
      } else if (
        document.documentElement.scrollTop < 0 ||
        document.body.scrollTop < 0
      ) {
        setNavbarColor("#FFF");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });


  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            title="SEB Store"
          >
            
          <img src={logo} style={{height:40}} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
            <UncontrolledDropdown>
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          className="btn-round"
          color="secondary"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
          Categorias de produtos
        </DropdownToggle>
        <DropdownMenu aria-labelledby="dropdownMenuButton">

        {categorias.map(categoria => (            
            <DropdownItem key={categoria._id} href={`/categoria/${categoria._id}`}>
            {categoria.nome}
            </DropdownItem>
             ))}          
     
        </DropdownMenu>
      </UncontrolledDropdown>
            </NavItem>
            
           

            <NavItem>
              <Button
                className="btn-round"
                color="danger"
                href="/minhas-trocas"
                  >
                Trocas
              </Button>
            </NavItem>
            
           
            <NavItem>
              <Button
                className="btn-round"
                color="info"
                href="/boots"
              >
                <TotalBoots /> <i className="nc-icon nc-spaceship"></i> Boot's
              </Button>
            </NavItem>


            <NavItem>
            <NavLink
                data-placement="bottom"
                href="/perfil"
              >
                <SlUser />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                style={{cursor:"pointer"}}
                href="/busca"
              >
                <SlMagnifier />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              style={{cursor:"pointer"}}
                data-placement="bottom"
                onClick={() => setLargeModal(true)}
              >
                <SlQuestion />
              </NavLink>
            </NavItem>
            <NavItem>
           
              <NavLink
                data-placement="bottom"
                href="/sair"
              >
                <SlPower />
              </NavLink>
            </NavItem>


            {user.tipo === "1"
            ?
            <NavItem>
              <Button
              className="btn-round"
              color="info"
              href="/gerenciador"
              target="_Blank"
              >
              Gerenciador
              </Button>
            </NavItem>
            : 
            <span></span>
            }

            



          </Nav>
        </Collapse>
        <Modal
        isOpen={largeModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal(false)}
      >
        <div className="modal-header">
          <h1 className="modal-title" style={{fontWeight:600}} id="myLargeModalLabel">
            Precisando de Ajuda?
          </h1>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setLargeModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">

        SEB Boost's: Este nome sugere que os pontos são um impulso para o usuário alcançar seus objetivos de resgatar produtos da marca.<br /><br />

        O sistema seria online, onde os usuários poderiam se cadastrar e acumular pontos por meio de ações específicas, como comprar produtos da marca, realizar tarefas ou metas estabelecidas, participar de eventos ou responder a pesquisas.<br /><br />

Os pontos acumulados pelos usuários seriam registrados em suas contas pessoais no sistema e eles poderiam resgatá-los por produtos da marca na loja online da empresa.<br /><br />

Para tornar o sistema mais atraente e engajador, pode-se oferecer diferentes níveis de pontos, com benefícios exclusivos para usuários que alcançarem níveis mais altos. Por exemplo, usuários que atingem um determinado número de pontos podem ter acesso a produtos exclusivos ou descontos especiais.<br /><br />

Além disso, pode-se criar um sistema de gamificação, onde os usuários são incentivados a competir entre si para acumular mais pontos e subir de nível. Isso pode aumentar a participação dos usuários e incentivar o engajamento com a marca.<br /><br />

Para ajudar os usuários a acompanhar seus pontos e níveis, o sistema pode incluir uma área de dashboard, onde eles podem visualizar seus pontos acumulados, níveis alcançados e prêmios disponíveis para resgate.<br /><br />

Outra funcionalidade interessante seria permitir que os usuários compartilhem seus pontos com outros usuários, por exemplo, com colegas de trabalho, amigos ou familiares. Isso pode aumentar a interação entre os usuários e a percepção da marca.<br /><br />

Finalmente, é importante ter uma comunicação clara e eficaz sobre o sistema de troca de pontos, suas regras e benefícios. Isso pode ser feito por meio de e-mails, notificações no sistema, banners na loja online e outros canais de comunicação. Dessa forma, os usuários serão mais propensos a participar e se engajar com a marca.<br /><br />

        Registro: A jornada do usuário começa com o registro no sistema. Nesta etapa, o usuário deve fornecer seus dados básicos, como nome, endereço de e-mail, senha e outros detalhes relevantes, como endereço de entrega e informações de pagamento.<br /><br />

Acumulação de pontos: Após o registro, o usuário deve acumular pontos para poder trocá-los por produtos. Os pontos podem ser ganhos por meio de várias ações, como fazer compras, compartilhar o sistema nas redes sociais, escrever avaliações de produtos, entre outros.<br /><br />

Escolha do produto: Quando o usuário acumular pontos suficientes, ele poderá escolher o produto que deseja resgatar. O sistema deve oferecer várias opções de produtos para o usuário escolher.<br /><br />

Troca de pontos: Após escolher o produto, o usuário deve confirmar sua escolha e realizar a troca de pontos. O sistema deve garantir que o usuário tenha pontos suficientes para realizar a troca e, se não tiver, oferecer a opção de acumular mais pontos.<br /><br />

Entrega do produto: Após a confirmação da troca, o sistema deve informar o usuário sobre o prazo de entrega e fornecer o número de rastreamento da remessa para que o usuário possa acompanhar o status da entrega.<br /><br />

Suporte ao cliente: Em caso de problemas com a entrega ou qualquer outra questão relacionada ao sistema, o usuário deve poder contatar o suporte ao cliente por meio do sistema para resolver suas dúvidas ou problemas.<br /><br />

Integer urna quam, dignissim elementum tincidunt et, placerat a leo. Donec rhoncus tellus sit amet risus congue, et venenatis velit eleifend. Proin non orci ac eros molestie pellentesque. Aliquam ut dolor eget orci dignissim pulvinar. Etiam congue facilisis ex, a gravida nulla cursus ac. Curabitur ac magna ut nisl auctor finibus. Pellentesque efficitur imperdiet nisi id tempus. Nulla ac sollicitudin orci, at vulputate diam. Duis efficitur, turpis ut dapibus blandit, velit diam vehicula diam, quis tristique ante erat sit amet purus. Integer euismod pharetra tellus in vulputate. Aenean ac arcu sit amet urna interdum efficitur. Morbi non varius nibh. Integer in auctor quam. Integer vel sollicitudin justo, id congue dolor.<br /><br />

Curabitur blandit nibh id ultricies semper. Proin pellentesque, tortor ac varius ullamcorper, sem mauris ullamcorper est, vitae pretium mauris enim vitae libero. Morbi sagittis, massa ac laoreet tincidunt, justo massa posuere risus, sit amet viverra arcu turpis eget ipsum. Praesent a mi lacus. Sed in tincidunt lacus. Fusce nec mauris elementum, tincidunt arcu a, congue dui. Suspendisse consequat sapien non tortor aliquam, at tincidunt nisl vulputate. Vestibulum in nisi ligula. Sed dapibus bibendum elit, sed volutpat nibh vestibulum vitae. In hac habitasse platea dictumst. Vestibulum aliquet maximus turpis at posuere. Donec non ornare ex. Sed in massa sed est egestas porttitor.<br /><br />

Proin eu dignissim diam, vitae imperdiet sapien. Integer aliquam massa ut erat efficitur, aliquet malesuada elit auctor. Quisque vulputate sollicitudin erat a finibus. Vivamus molestie quis sapien nec laoreet. Etiam volutpat laoreet enim. Etiam pulvinar, nulla porttitor dapibus luctus, lectus lorem semper mauris, vel aliquet massa est consectetur magna. Curabitur cursus est turpis, nec cursus risus volutpat in. Etiam nec dui quis nunc tincidunt volutpat. Sed vehicula facilisis mi, euismod finibus mi condimentum pretium. Praesent fermentum non libero convallis volutpat. Integer scelerisque, turpis id fringilla semper, mi nisi mattis purus, eget aliquam ex urna sed dolor. Aenean sapien odio, congue congue nibh eu, feugiat facilisis leo. Aenean id malesuada metus. Sed ac leo id massa ullamcorper vulputate.<br /><br />

Nunc sit amet lacus sed nunc placerat convallis a id tellus. Mauris sed eros varius, mollis diam nec, fringilla metus. Etiam et felis nec diam sagittis mattis a eu risus. Sed eu gravida dui. Integer molestie diam et sapien ultrices pretium. Curabitur nec auctor erat, et sodales velit. Nulla mollis maximus hendrerit. Cras porta a dui sit amet rhoncus. In eleifend est non tristique venenatis. Suspendisse vehicula at leo sit amet commodo.
        </div>
      </Modal>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
