import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import api from "../../services/api.js";
import TotalBoots from "components/Headers/TotalBoots.js";


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Alert,
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label
} from "reactstrap";


class SectionMinhasCompras extends Component {

 
  constructor(props) {
    super(props);
    this.state = {     
      boots: [],
      trocas:[],
      tipoboots: {},
      produto:{},
      modal: false,
      total:"0",
      total2:"0",
    };

    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


   async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    
    axios.get(`https://www.rocketleoo.com.br/sebstore/api/boots-user/${user._id}`)
    .then((response) => {
      const boots = response.data;
      const tipoboots = response.data.tipoboots;
      this.setState({
        boots,
        tipoboots,
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)
      })
       
    })     
    
    
    axios.get(`https://www.rocketleoo.com.br/sebstore/api/troca-user/${user._id}`)
    .then((response) => {
      const trocas = response.data;
      const produto = response.data.produto;
      this.setState({
        produto,
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
      console.log( trocas );
   
    })

  }


    renderUnidades() {
      return this.state.trocas.map((troca, i) => {

         
          return (
            <tr key={troca._id}>    
            <td> {moment(troca.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:600}}> {troca.produto.nome}</td>               
            <td style={{fontWeight:600, color:"#F5593D"}}> - <i className="nc-icon nc-spaceship"></i> {troca.boots} </td> 
            <td style={{fontWeight:200}}>
           
            {(() => {
        switch (troca.status) {
          case '0':
            return <Alert color="danger" size="sm" style={{padding:6, textAlign:"center"}}> Cancelado </Alert>
          case '1':
            return <Alert color="warning" size="sm" style={{padding:6, textAlign:"center"}}> Aguardando confirmação </Alert>
          case '2':
            return <Alert color="primary" size="sm" style={{padding:6, textAlign:"center"}}> Confirmado</Alert>
          case '3':
            return <Alert color="primary" size="sm" style={{padding:6, textAlign:"center"}}> Enviado</Alert>
          case '4':
            return <Alert color="success" size="sm" style={{padding:6, textAlign:"center"}}> Entregue</Alert>
          default:
            return null
        }
      })()}
            
            </td> 
            <td>
                  <Button
                    size="sm"
                    color="secundary"
                    href={`/troca-select/${troca._id}`}
                    >
                <FiEye />
                  </Button>
                  </td> 
            </tr>
          );
      });
  }
  
 
 

  render() {

    const { total, total2 } = this.state;

  return (
    <>
      <IndexNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
      <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> | Trocas
          </div>
         
          <Row style={{marginTop:0}}>
              <Col sm>
              <div style={{fontWeight:600,}}>
            <h1>Trocas</h1>
          </div>
                </Col>
                <Col sm>
              <div style={{fontWeight:600, fontSize:20}}>
               Boot's trocados<br /><span style={{fontWeight:600, fontSize:50, color:"#F5593D"}}>- <i className="nc-icon nc-spaceship"></i>  {total2} Boot's</span>
          </div>
                </Col>
                </Row>


          <br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Novo Produto</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com os dados da Unidade e clique em salvar ou cancelar.
            <br /><br />
            
                 

            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>


   

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th>Produto</th>  
          <th>Boot's</th>  
          <th>Status</th>   
          <th width="5%">&nbsp;</th> 
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     

        </div>
      </div>
    </>
  );
}}

export default withRouter(SectionMinhasCompras);
