
import React from "react";

// reactstrap components

// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections
import SectionBootsAdmin from "views/index-sections/SectionBootsAdmin.js";

function BootsAdmin() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <GerenciadorNavbar />
      <div className="main">
        <SectionBootsAdmin />
        <DemoFooter />
      </div>
    </>
  );
}

export default BootsAdmin;
