import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import axios from 'axios';
import TotalBoots from "../../components/Headers/TotalBoots.js";

// reactstrap components
import {
  Button,
  Collapse,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Label,
  CardImg 
} from "reactstrap";



class SectionProduto extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.state = { collapse: false };
    console.log(this.props.match.params.id)
    this.state = {
        produto : [],
        categoria:{},
        boots: [],
        unidade:{},
        user:[],
        trocas:[],
        total:"0",
        total2:"0",
        user2: props.user2,
        produto2: props.produto2,
        boots2: props.boots2,
        status2: props.status2,
        selectedPost: null,
       };    
           
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

 
  toggle2() {
    this.setState({
      modal: !this.state.modal
    });
  }

  async componentDidMount(){  
    const userid = JSON.parse(localStorage.getItem('user'));
    axios.get(`https://www.rocketleoo.com.br/sebstore/api/user/${userid._id}`)
    .then(res => {
      const user = res.data;
      const unidade = res.data.id_unidade;
      this.setState({ user, unidade});
    })

    axios.get(`https://www.rocketleoo.com.br/sebstore/api/boots-user/${userid._id}`)
    .then((response) => {
      const boots = response.data;
      this.setState({
        boots,        
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
       
    })

    axios.get(`https://www.rocketleoo.com.br/sebstore/api/troca-user/${userid._id}`)
    .then((response) => {
      const trocas = response.data;
      this.setState({
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
      console.log( trocas );
   
    })
    
    const { id } = this.props.match.params;       
    axios.get(`https://www.rocketleoo.com.br/sebstore/api/produtos/${id}`)
    .then(res => {
      const produto = res.data;
      const categoria = res.data.categoria;
      this.setState({ produto, categoria});
    })
  };

  handleChangeUser = (event) => {
    event.persist();
    this.setState({user2: event.target.value });
  }

  handleChangeProduto = (event) => {
    event.persist();
    this.setState({produto2: event.target.value });
  }

  handleChangeBoots = (event) => {
    event.persist();
    this.setState({boots2: event.target.value });
  }

  handleChangeStatus = (event) => {
    event.persist();
    this.setState({status2: event.target.value });
  }


  handleSubmit = event => {
    event.preventDefault();   
    axios.post(`https://www.rocketleoo.com.br/sebstore/api/troca`, { 
      user: this.state.user._id,
      produto: this.state.produto._id,
      boots: this.state.produto.boots, 
      status: "1" 
    })
    .then(res => {
      alert('Troca efetuada com sucesso!');
      this.props.history.push("/minhas-trocas");
      window.location.reload();
    })
    .catch(function (error) {
      alert('Ocorreu algum erro!');
      console.log(error);
     });

     const config = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
    };

      
  axios.put(`https://www.rocketleoo.com.br/sebstore/api/produto/${this.state.produto._id}`, {
    estoque: parseInt(this.state.produto.estoque) - 1, 
    }, config)
      .then(res => {
        alert('Produto editado com sucesso!');   
      })
      .catch(function (error) {
        alert('Ocorreu algum erro!');
        console.log(error);
    });
  
  
    }



  render() {

    const { produto, categoria, user, unidade, total, total2, img } = this.state; 

    const bootstotal = total - total2;

    const baseUrl = "../../../public/uploads/";
    


  return (
    <>
      <div className="section section-buttons">
        <Container>
        <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> <a href={`/categoria/${categoria._id}`} style={{fontWeight:600,}}> | {categoria.nome}</a> | {produto.nome}
          </div>
          <div style={{fontWeight:600,}}>
            <h1>{produto.nome}</h1>
          </div>

          <hr />
        <Row style={{marginTop:0}}>
              <Col sm>
              <Card style={{width:"80%"}}>
              <CardBody>
              <CardImg src={`${baseUrl+produto.img}`} style={{height:150, width:"100%"}} />
          
          
 
              

                </CardBody>
              </Card>
              </Col>


              <Col sm>
              {produto.desc}

<hr />
<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  

<Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:18, color:"#F5593D"}}>Estoque:</span>  {produto.estoque} unidades.
                  </Col>          
                </Row>
</div>

<hr />
<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  
<Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:18, color:"#F5593D"}}>Local de entrega:</span> {unidade.nome}
&nbsp; &nbsp; &nbsp; |  &nbsp; &nbsp; &nbsp; 

                  <a onClick={this.toggle}  style={{cursor:"pointer", fontWeight:600}}><u>Endereço</u></a>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
            <FormGroup>
          <Label for="endereco"><span style={{fontWeight:600}}>Endereço</span></Label><br />
          {unidade.endereco}
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
          <Label for="complemento"><span style={{fontWeight:600}}>Complemento</span></Label><br />
          {unidade.complemento}
          </FormGroup>
          <FormGroup className="col-md-6">
          <Label for="bairro"><span style={{fontWeight:600}}>Bairro</span></Label><br />
          {unidade.bairro}
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="cidade"><span style={{fontWeight:600}}>Cidade</span></Label><br />
            {unidade.cidade}
          </FormGroup>
          <FormGroup className="col-md-4">
            <Label for="estado"><span style={{fontWeight:600}}>Estado</span></Label><br />
            {unidade.estado}
          </FormGroup>
          <FormGroup className="col-md-2">
            <Label for="cep"><span style={{fontWeight:600}}>CEP</span></Label><br />
            {unidade.cep}
         
          </FormGroup>
          </div>
            </CardBody>
          </Card>
        </Collapse>
                  
                  </Col>          
                </Row>
                <Row>
             
                </Row>
</div>
<hr />
<Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:18, color:"#F5593D"}}>Quantidade Boot's:</span> <span style={{fontWeight:400}}><TotalBoots  /></span> <i className="nc-icon nc-spaceship"></i>.
                  </Col>          
                </Row>

<hr />

     


<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  
                <Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:42, color:"#F5593D"}}>{produto.boots} <i className="nc-icon nc-spaceship"></i></span>
                  </Col>
                  <Col style={{marginTop:10}}>

                  {parseInt(produto.estoque) <= 0
        ?  <Button
        className="btn-round"
        color="secundary"
        style={{width:"100%"}} 
        disabled="disabled"
        >
        Produto esgotado
          </Button>
            :  
              <>
              {parseInt(bootstotal) < parseInt(produto.boots)
        ?  <Button
        className="btn-round"
        color="secundary"
        style={{width:"100%"}} 
        disabled="disabled"
        >
        Boot's insuficientes
          </Button>
            :  <Button
            className="btn-round"
            color="danger"
            style={{width:"100%"}} 
            onClick={this.toggle2}
            >
        Trocar Boot's
          </Button>
          }
              </>
            
          }





                 
                  </Col>
                </Row>
                </div>

      
              </Col>
              
            
            </Row>



            <Modal isOpen={this.state.modal} toggle={this.toggle2} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>{produto.nome}  </span></ModalHeader>
          <ModalBody>
            <h3>
            Confirme seu pedido de troca de Boot's.
            </h3>
            <br /><br />
            
                    
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Efetuar troca</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle2}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>
                
        </Container>
      </div>
    </>
  );
}

}

export default withRouter(SectionProduto);
