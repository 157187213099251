import React, { Component } from "react";
import { withRouter } from "react-router-dom";


// reactstrap components
import { Button, Card, Form, Input, Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import api from "services/api";
import { login } from "services/auth";



class RecuperarSenha extends Component {
  state = {
    email: "",
    email2: "",
    senha: "",
    error: ""
  };

  

  handleSignIn = async e => {
    e.preventDefault();
    const { email, senha } = this.state;
    if (!email || !senha) {
      this.setState({ error: "* Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/auth/authenticate", { email, senha });
        const token = response.data.token; 
        const userlog = response.data.user;
        const user = JSON.stringify(userlog);

        login(user, token);     
        console.log(user, token)   
        this.props.history.push("/index");
        
      } catch (err) {
        
        this.setState({
          error:
            "* Houve um problema com o login, por favor verifique suas credenciais ou entre em contato com o Eventos do Grupo SEB."
        });
      }
    }
  };

  render() {
    return (
    <>
      <ExamplesNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_login.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <Container>
        <Form onSubmit={this.handleSignIn}>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto" style={{textAlign:"center", fontWeight:600}}>Bem-vindo à<br /> SEB Store!</h3>
                <div className="social-line text-center">
                Por favor, informe o e-mail cadastrado que lhe enviaremos informações para você recuperar sua senha.
                </div>               
                  <label>E-mail</label>
                  <Input 
                  type="email" 
                  placeholder="E-mail" 
                  onChange={e => this.setState({ email: e.target.value })}
                   />

                 
                   <br />
                   <span style={{color:"black", fontWeight:600,}}>
                   {this.state.error && <p>{this.state.error}</p> }
                   </span>


                  <Button block className="btn-round" color="danger" type="submit">
                  RECUPERAR SENHA
                  </Button>
 
                <div className="forgot">
                  <Button
                    className="btn-link"
                    color="danger"
                    href="/"
                   
                  >
                    FAZER LOGIN
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
          </Form>
        </Container>
        <div className="footer register-footer text-center">
          <h6>
            © {new Date().getFullYear()}, feito com{" "}
            <i className="fa fa-heart heart" /> pelo Grupo SEB.
          </h6>
        </div>
      </div>
    </>
  );
}}

export default withRouter(RecuperarSenha);
