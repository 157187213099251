
import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";


// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";


class UnidadeNegocioAdmin extends Component {

 
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      unidades: [],
      unidade:[],
      filter:[],
      filterlist:[],
      modal: false,
      modal2: false,
      modal3: false,
      nome: props.nome,
      nome2: props.nome2,
      selectedPost: null,
      id: null,
      searchedVal:""
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  


  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  toggle3 = i => {
    this.setState({
      modal3: !this.state.modal3,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };



  componentDidMount() {
    axios.get(`https://www.rocketleoo.com.br/sebstore/api/unidade-negocio`)
      .then(res => {
        const unidades = res.data;
        const filter = res.data;
        this.setState({ unidades, filter });
      })

  }


handleChangeId = event => {
  event.persist();
  this.setState({ id: event.target.value });
}

  handleChange = event => {
    this.setState({ nome: event.target.value });
  }


  handleChange2 = event => {
    this.setState({ nome2: event.target.value });
  }




  handleSubmit = event => {
    event.preventDefault();
    

    axios.post(`https://www.rocketleoo.com.br/sebstore/api/unidade-negocio`, { nome: this.state.nome })
    .then(res => {
      alert('Unidade criada com sucesso!');
      this.props.history.push("/unidade-negocio-admin");
      window.location.reload();
    })
    .catch(function (error) {
      alert('Ocorreu algum erro!');
      console.log(error);
     });
    }

    handleUpdate = event => {
      event.preventDefault();  

      if (this.state.selectedPost !== null) {
        const unidade = this.state.unidades[this.state.selectedPost];
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://www.rocketleoo.com.br/sebstore/api/unidade-negocio/${unidade._id}`, {nome: this.state.nome}, config)
          .then(res => {
            alert('Unidade editada com sucesso!');
            this.props.history.push("/unidade-negocio-admin");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }

      }


      handleDelete = event => {
        event.preventDefault(); 
        if (this.state.selectedPost !== null) {
          const unidade = this.state.unidades[this.state.selectedPost];

          axios.delete(`https://www.rocketleoo.com.br/sebstore/api/unidade-negocio/${unidade._id}`)
          .then(res => {
            alert('Unidade excluída com sucesso!');
            this.props.history.push("/unidade-negocio-admin");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      
        }};

      


    renderUnidades() {
      return this.state.filter.map((unidade, i) => {
          return (
            <tr key={unidade._id}>    
            <td> {moment(unidade.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:400}}> {unidade.nome}</td>      
            <td><Button
                    size="sm"
                    color="info"
                    onClick={() => this.toggle2(i)}
                    
                    >
                <FiEdit2 />
                  </Button></td>   
                  <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => this.toggle3(i)}
                    >
                <FiX />
                  </Button>
                  </td>
            </tr>
          );
      });
  }
  
  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const unidade = this.state.unidades[this.state.selectedPost];
      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Editar Unidade de Negócio</span></ModalHeader>
          <ModalBody>
          Utilize o formulário para editar o nome da Unidade de Negócio e clique em salvar ou cancelar.
            <br /><br />
            
                  <label htmlFor="nome"><span style={{fontWeight:600}}>Unidade de Negócio</span></label>
                  <Input 
                  placeholder={unidade.nome}
                  defaultValue={unidade.nome}
                  value={this.state.nome}
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChange}
              
                   /> 
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" href="/unidade-negocio-admin">Cancelar</Button>
          </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  renderModal2 = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const unidade = this.state.unidades[this.state.selectedPost];
      return (
      
          <Form name="handleDelete" onSubmit={this.handleDelete}>
          <ModalHeader><span style={{fontWeight:600}}>Excluir Unidade de Negócio</span></ModalHeader>
          <ModalBody>
          Confirme a exclusão da Unidade de Negócio clicando em excluir ou cancelar.
            <br /><br />
            
           <label htmlFor="nome"><span style={{fontWeight:600}}>{unidade.nome}</span></label>             
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="danger" type="submit">Excluir</Button>{' '}
            <Button outline size="sm" color="danger" href="/unidade-negocio-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  render() {

    const { unidades } = this.state;


    
    const requestSearch = (searchedVal) => {
      const filteredRows = unidades.filter((row) => {
          return row.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      });
      if (searchedVal.length < 1) {
          this.setState({filter: unidades});
      }
      else {
        this.setState({filter: filteredRows});
      }
    };

    

  return (
    <>
      <GerenciadorNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Unidades de Negócios</h1>
          </div>

        
          <Row>
            <Col sm style={{textAlign:"right"}}>
 <Button
                size="sm"
                color="success"
                onClick={this.toggle}
                >
            nova unidade
              </Button>       
           
            </Col>
          </Row>

          <br />

          <form>
        <Row style={{marginTop:0}}>
          <Col>    


          <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
        </InputGroupAddon>
        <Input type="search" placeholder="Digite o nome da unidade..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
      </InputGroup>
        
   
   
          </Col>                   
        </Row>
      </form>

      <br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Nova Unidade de Negócio</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com o nome da Unidade de Negócio e clique em salvar ou cancelar.
            <br /><br />
            
                  <label htmlFor="nome"><span style={{fontWeight:600}}>Unidade de Negócio</span></label>
                  <Input 
                  placeholder="Digite um nome..."
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChange}
                   />
            
            
          </ModalBody>
          <ModalFooter>
            <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>


        <Modal isOpen={this.state.modal2} className={this.props.className}>
        {this.renderModal(this.state.unidades[this.state.selectedPost])}
        </Modal>

        <Modal isOpen={this.state.modal3} className={this.props.className}>
        {this.renderModal2(this.state.unidades[this.state.selectedPost])}
        </Modal>


        

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th width="75%">Unidade de Negócio</th>   
          <th width="5%">&nbsp;</th>   
          <th width="5%">&nbsp;</th>  
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     
        <DemoFooter />
        </div>
      </div>
    </>
  );
}}

export default withRouter(UnidadeNegocioAdmin);
