/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections
import Gerenciador2 from "views/index-sections/Gerenciador.js";

function Gerenciador() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <GerenciadorNavbar />  
      <div className="main">
        <Gerenciador2 />
        <DemoFooter />
      </div>
    </>
  );
}

export default Gerenciador;
